/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import coupleAtBeach from '../../images/heroes/couple-walking-on-beach.jpg'
import radishPicking from '../../images/people/radish-picking.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../../components/Countdown'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import {
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-footer1',
    'disclaimer-amd-medicareadvantagelp',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            ' Aetna Medicare | Find the right plan for your individual coverage ',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/lp/aetna-medicare',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Aetna Medicare | Find the right plan for your individual coverage ',
    defaultRobots: 'follow,index',
    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero background-image-position-right"
              image={
                <img
                  src={coupleAtBeach}
                  alt="elderly couple walking on the beach and smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="dark">
                    Aetna Medicare coverage{' '}
                  </Typography>

                  <Typography variant="h4" color="dark">
                    Peace of mind with Medicare plans.{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />
        <CountdownTimer />

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Where are you in your Medicare journey?
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column
                className="card center-content justify-content-center"
                backgroundColor="primary"
              >
                <Link
                  variant="feature"
                  to="#medicare-explanation"
                  color="primary"
                  className="margin-x-auto"
                >
                  <Typography variant="h4" color="light">
                    I'm almost 65:
                    <br />
                    What is Medicare?
                  </Typography>
                </Link>
              </Column>
              <Column
                className="card center-content justify-content-center"
                backgroundColor="primary"
              >
                <Link
                  variant="feature"
                  to="#right-plan"
                  color="primary"
                  className="margin-x-auto"
                >
                  <Typography variant="h4" color="light">
                    I'm looking for the right plan
                  </Typography>
                </Link>
              </Column>
              <Column
                className="card center-content justify-content-center"
                backgroundColor="primary"
              >
                <Link
                  variant="feature"
                  to="#ready-to-enroll"
                  color="primary"
                  className="margin-x-auto"
                >
                  <Typography variant="h4" color="light">
                    I'm ready to enroll
                  </Typography>
                </Link>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <SplitContent
          id="medicare-explanation"
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={radishPicking}
              alt="an elderly man picks radishes in a garden with his grandaughter"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Your life supported with an Aetna Medicare plan.
              </Typography>
              <Typography variant="body">
                Aetna Medicare plan offerings span a wide range of coverage
                options including Medicare Advantage plans, Prescription Drug
                plans and Supplement plans.
              </Typography>
              <Typography variant="h4">
                Our plans may include coverage for:
              </Typography>
              <List>
                <ListItem>Prescription drugs</ListItem>
                <ListItem>Dental care and vision</ListItem>
                <ListItem>Hearing aids</ListItem>
                <ListItem>Fitness memberships</ListItem>
              </List>
            </>
          }
        />

        <VariableContent
          id="right-plan"
          mainContent={
            <>
              <Typography variant="h2">
                With a variety of coverage options, Aetna Medicare has the right
                plan for you.
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-heart.svg"
                    alt="heart icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Advantage
                  </Typography>
                  <Typography variant="body" color="primary">
                    Aetna Medicare Advantage plans cover the same health
                    services as Original Medicare, but with additional benefits
                    like a 24 hour nurse hotline or Prescription home delivery.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/advantage"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-hospital.svg"
                    alt="hospital icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare PPO
                  </Typography>
                  <Typography variant="body" color="primary">
                    Medicare PPO plans are just one of the Medicare Advantage
                    options but it may cost you more if you do not use a network
                    provider. Medicare PPO plans provide you with Original
                    Medicare benefits.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/ppo"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-network.svg"
                    alt="network icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare HMO
                  </Typography>
                  <Typography variant="body" color="primary">
                    Health maintenance organization (HMO) plans provide you with
                    Original Medicare benefits but requires you to use hospitals
                    and doctors within a certain network.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/hmo"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-pill-bottle.svg"
                    alt="pill-bottle icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Dual Special Needs Plan (DSNP)
                  </Typography>
                  <Typography variant="body" color="primary">
                    If you carry both a Medicare and Medicaid card, you may be
                    eligible for a DSNP that offers its own set of benefits,
                    though specifics will vary based on location.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/lp/dsnp"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          id="ready-to-enroll"
          backgroundColor="lightGray"
          mainContent={
            <>
              <Typography variant="h2">
                Ready to enroll? Choose one of three, easy steps:
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <Typography variant="h6" color="primary">
                    Speak to an agent
                  </Typography>
                  <StaticImage
                    src="../../images/icons/purple-phone.svg"
                    alt="phone icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="body">
                    Call an agent today for a free consultation.
                    <br />
                    TTY: 711, available 24/7
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  className="margin-x-auto"
                >
                  Call {rotatedNumber}
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <Typography variant="h6" color="primary">
                    Submit a request form
                  </Typography>
                  <StaticImage
                    src="../../images/icons/purple-checklist.svg"
                    alt="checklist icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="body">
                    Fill out a request form to begin the enrollment process in
                    the plan that's right for you.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Fill out a form
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <Typography variant="h6" color="primary">
                    Schedule a call for later!
                  </Typography>
                  <StaticImage
                    src="../../images/icons/purple-calendar.svg"
                    alt="calendar icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="body">
                    Is now not a good time? Let us call you on your schedule.
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/schedule"
                  color="primary"
                  className="margin-x-auto"
                >
                  Schedule a call today
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
